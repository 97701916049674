.inner.sec5__inner {
    height: 100%;
    padding-bottom: 11.25rem;
    justify-content: space-evenly;
}

.sec5__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.8rem;
}

.sec5__content .contact-box {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.sec5__content .contact-box .contact-text {
    height: 100%;
    font-size: 2.5rem;
    font-weight: 700;
}
.sec5__content .contact-box .contact-text:nth-child(1) {
}

.sec5__content .contact-box .contact-text.address {
    display: flex;
    gap: 1rem;
    height: 100%;
}

.sec5__content .contact-box .contact-text.address span {
    font-size: 1.5rem;
    font-weight: 600;
}

.sec5__content .contact-box .contact-text.address span:last-child {
    font-size: 1.5rem;
    font-weight: 400;
}

.sec5__content .map-wrapper {
    height: 46vh;
    overflow: hidden;
    width: 100%;
    border: 0.0625rem solid #6b7280;
    background-image: url("http://placehold.it/1404X410");
}
/* 태블릿 가로 */
@media all and (min-width: 1024px) and (max-width: 1440px) {
    .inner.sec5__inner {
        justify-content: flex-start;
        gap: 2vh;
    }
    .sec5__content {
        height: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0.625rem;
        padding-bottom: 1.5rem;
    }
    .sec5__content .contact-box {
        width: auto;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }

    .sec5__content .contact-box .contact-text:nth-child(1) {
        font-size: 2rem;
    }
    .sec5__content .contact-box .contact-text.address {
        width: 32vw;
    }
    .sec5__content .contact-box .contact-text.address span, .sec5__content .contact-box .contact-text.address span:last-child{
        font-size: 1.25rem;
        line-height: 2rem;
    }

    .sec5__content .map-wrapper {
        width: 70%;
        height: 100%;
    }
}
/* 태블릿 세로 */
@media all and (min-width: 753px) and (max-width: 1023px) {
    .inner.sec5__inner {
        justify-content: flex-start;
        gap: 2rem;
    }
    .sec5__content {
        height: 100%;
        justify-content: space-between;
        padding-bottom: 2rem;
    }
    .sec5__content .contact-box .contact-text:nth-child(1) {
    }
    .sec5__content .contact-box {
        justify-content: space-between;
        align-items: center;
    }
    .sec5__content .contact-box .contact-text.address {
        width: 50vw;
        line-height: 2rem;
    }
    .sec5__content .map-wrapper {
        height: 100%;
    }
}
/* 모바일 */
@media all and (max-width: 752px) {
    .inner.sec5__inner {
        justify-content: space-evenly;
    }
    .sec5__content .contact-box {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
    }
    .sec5__content .contact-box .contact-text {
        font-size: 1.5rem;
    }
    .sec5__content .contact-box .contact-text.address span, .sec5__content .contact-box .contact-text.address span:last-child {
        font-size: 0.875rem;
        line-height: 1.125rem;
    }
    .sec5__content .map-wrapper {
        height: 36vh;
    }
}