/* ------------------------리뷰모달 */

/* 수정 */
.modal-content-wrapper .reviewSwiper {
    width: 100%;
    height: calc(20rem + 1vw);
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.reviewSwiper .swiper-wrapper {
    height: 100%;
}

.reviewSwiper .swiper-slide {
    width: 100%;
    height: 25rem;
    text-align: center;
    font-size: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviewSwiper .swiper-slide img {
    display: block;
    height: 100%;
    object-fit: cover;
}

.reviewSwiper .swiper-pagination {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.reviewSwiper .swiper-pagination .swiper-pagination-bullet {
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    font-size: 1.125rem;
    color: transparent;
    background-color: #000;
}

.reviewSwiper
.swiper-pagination
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #409cb5;
}

.reviewSwiper .swiper-button-next::after,
.reviewSwiper .swiper-button-prev::after {
    font-size: 2rem;
}

.reviewSwiper .swiper-button-prev,
.reviewSwiper .swiper-button-next {
    color: #fff;
}

/* review 내용영역 */
.review__details {
    background-color: #fff;
    padding: 1rem;
    height: calc(24rem + 1vw);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    overflow: hidden;
    width: 100%;
}

.review__bottom {
    width: 100%;
}

.review__details .review__company {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.review__details .review__company p.company-name {
    font-size: 1.25rem;
    font-weight: 600;
    color: #6b7280;
}

.review-content {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.review-content .review__title {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.25rem 0;
    white-space: normal; /* 자동 줄바꿈 허용 */
    overflow-wrap: break-word; /* 긴 단어를 줄바꿈 */
    word-break: break-word; /* 단어가 너무 길 경우 줄바꿈 */
}

/* 수정 */
.review-content .service-duration {
    padding: 0.25rem 0;
    color: #6b7280;
    font-size: 0.875rem;
    font-weight: 500;
}

/* 수정 */
.review-content .review__context {
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    flex-grow: 1;
    overflow: auto;
    white-space: normal; /* 자동 줄바꿈 허용 */
    overflow-wrap: break-word; /* 긴 단어를 줄바꿈 */
    word-break: break-word; /* 단어가 너무 길 경우 줄바꿈 */
    max-width: 100%; /* 요소의 최대 너비를 부모 요소 너비로 제한 */
}

/* 수정 */
.review-content::-webkit-scrollbar {
    display: none;
}

/*수정필요 20241015 */
.sec4::after {
    bottom: 0;
    height: 92%;
    background-image: url("/src/images/mainpage/review-avatar1.png");
    background-repeat: no-repeat;
    background-position: left bottom;
}
/* -------------------------------------------------------리뷰모달 끝 */

.sec4 {
    position: relative;
    z-index: 2;
}

.sec4::before,
.sec4::after {
    content: "";
    position: absolute;
    width: 100%;
}

.sec4::before {
    background-color: #ecf5f8;
    bottom: 16%;
    height: 64%;
}

.sec4_noData {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sec4_noData > * {
    font-size: 1.5rem;
    font-weight: 600;
}

.inner.sec4__inner {
    position: relative;
    height: 100vh;
    z-index: 1;
    justify-content: flex-start;
}

.subtitle.sec4__subtitle {
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.subtitle.sec4__subtitle img {
    width: 5rem;
}

.sec4__contents {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
}

.sec4__contents .review-wrapper {
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem 1.5rem;
    align-items: center;
    justify-content: center;
}

.review-wrapper .review-card {
    min-width: 12.5rem;
    width: 12.5rem;
    max-width: 12.5rem;
    height: fit-content;
    border-radius: 1.5rem;
    background: #fff;
    box-shadow: 0rem 0.25rem 0.625rem 0rem rgba(0, 0, 0, 0.4);
    display: flex;
    padding: 0.625rem;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.review-card .review__thumbnail {
    width: 100%;
    min-height: 7.5rem;
    text-indent: -6.25rem;
    overflow: hidden;
    border-radius: 1rem;
    border: 1px solid #ccc;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.review-card .review__bottom .review__company {
    height: 1.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
}

.review-card .review__bottom .review__company .company {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.review-card .review__bottom .review__company img.user {
    width: 1.25rem;
}

.review-card .review__bottom .review__company p.company-name {
    font-size: 1rem;
    font-weight: 600;
}

.review-card .review__bottom .review__company img.heart {
    width: 1.25rem;
}

.review-card .review__bottom .review-content {
    flex-grow: 1;
}

/* 수정 */
.review-card .review__bottom .review-content::-webkit-scrollbar {
    display: none;
}

.review-card .review__bottom .review-content .review__title {
    height: 2.5rem;
    font-size: 1.125rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.review-card .review__bottom .review-content .review__context {
    height: 2.75rem;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 160%;
    overflow: hidden;
}

.btn.more-btn {
    width: fit-content;
    height: fit-content;
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    cursor: pointer;
    display: flex;
    border-radius: 624.9375rem;
    background: #409cb5;
    box-shadow: 0rem 0.25rem 0.625rem 0rem rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
    gap: 1rem;
    transition: all 0.3s;
}

.btn.more-btn p {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
}

.btn.more-btn p.arrow {
    transition: all 0.3s;
    width: 2.875rem;
    height: 2.875rem;
    line-height: 2.875rem;
    border-radius: 624.9375rem;
    background-color: #fff;
    color: #2d6f81;
    font-size: 1.25rem;
}

.btn.more-btn:hover {
    transform: scale(1.1);
}

/* 태블릿 가로 */
@media all and (min-width: 1024px) and (max-width: 1440px) {
}

/* 태블릿 세로 */
@media all and (min-width: 753px) and (max-width: 1023px) {
}

/* 모바일 */
@media all and (max-width: 752px) {
    .sec4 .inner {
        height: 100vh;
        gap: 2vh;
    }
    .sec4::after {
        display: none;
    }

    .sec4__contents {
        height: auto;
    }
    .sec4 .subtitle img {
        width: 3.5rem;
        height: auto;
    }
    .sec4 .subtitle .desc-text.desc-text-1 {
        font-size: 1.5rem;
    }

    .sec4__contents .review-wrapper {
        gap: 0.5rem 0.5rem;
    }
    /* 241127 수정 */
    .review-wrapper .review-card {
        min-width:40vw;
        width: 40vw;
        max-width:40vw;
        padding: 0.5rem;
    }
    .review-card .review__thumbnail {
        min-height: 6rem;
        height: 6rem;
    }
    .review-card .review__bottom .review__company p.company-name {
        font-size: 0.875rem;
    }
    .review-card .review__bottom .review-content .review__title {
        padding: 0;
        font-size: 0.875rem;
        height: 1.5rem;
    }
    .review-card .review__bottom .review-content .review__context {
        height: 1.5rem;
        font-size: 0.875rem;
    }
    .sec4 .btn.more-btn {
        padding: 10px 10px 10px 20px;
        gap: 0.5rem;
    }
    .sec4 .btn.more-btn p{
        font-size: 1rem;
    }

    .sec4 .btn.more-btn p:nth-child(2){
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
    }
}