@charset "UTF-8";
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

/* 공통 스타일 */
* {
    font-family: "Pretendard", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
    font-size: 0.875rem;
    list-style: none;
    box-sizing: border-box;
    text-decoration: none;
    color: #000;
    -webkit-font-smoothing: antialiased; /* 웹킷 브라우저 폰트 렌더링 */
    -moz-osx-font-smoothing: grayscale; /* MacOS 폰트 렌더링 */
}

.hidden {
    display: none !important;
}

.wrapper {
    position: relative;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
}

.review-management {
    width: 89.25rem;
    height: 47.875rem;
    margin: auto;
    background-color: #fff;
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
}

.page-title {
    width: 100%;
    text-align: center;
    height: 2.5rem;
    line-height: 2.5rem;
    background: #2d6f81;
    font-size: 1.125rem;
    color: #fff;
    display: flex;
    justify-content: center;
}

.modal-exitIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.4rem;
}
.modal-title{
    width: 100%;
    text-align: center;
    height: 2.5rem;
    line-height: 2.5rem;
    background: #2d6f81;
    font-size: 1.125rem;
    color: #fff;
}


.review-management .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem 7.125rem;
    flex-grow: 1;
}

.admin_menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn-regist {
    width: 12.5rem;
    height: 3rem;
    border-radius: 1rem;
    background: #409cb5;
    color: #fff;
    font-weight: 700;
    font-size: 1rem;
    line-height: 3rem;
    text-align: center;
    cursor: pointer;
}
/**/
.table-review-management {
    width: 100%; /* 테이블 너비를 100%로 설정 */
    border-collapse: collapse; /* 테두리 겹침 방지 */
}

.table-review-management th,
.table-review-management td {
    padding: 10px; /* 셀 여백 */
    border: 1px solid #2d6f81; /* 테두리 색상 */
    text-align: center; /* 가운데 정렬 */
    vertical-align: middle; /* 수직 가운데 정렬 */
}

/*.table-review-management th {*/
/*    background-color: #2d6f81; !* 헤더 배경 색상 *!*/
/*    color: white; !* 헤더 글자 색상 *!*/
/*}*/

tbody tr:nth-child(odd) {
    background: #ecf5f8; /* 홀수 행 배경 색상 */
}

tbody tr:hover {
    background: #e0f7fa; /* 마우스 오버 시 색상 */
}

.cell {
    font-size: 14px; /* 셀 폰트 크기 */
}

/* 각 열의 너비 설정 */
.cell.cell-num {
    width: 3.75rem; /* 번호 열 너비 */
}

.cell.cell-title {
    width: 392px; /* 제목 열 너비 */
}

tbody .cell.cell-title {
    padding-left: 0.5rem; /* 제목 셀 왼쪽 여백 */
    text-align: left; /* 제목 셀 왼쪽 정렬 */
}

.cell.cell-company {
    width: 11.5rem; /* 회사명 열 너비 */
}

.cell.cell-date {
    width: 9.75rem; /* 날짜 열 너비 */
}

.cell.cell-visibility {
    width: 6rem; /* 노출 설정 열 너비 */
}

/**/

thead {
    height: 2rem;
}

thead tr {
    border-bottom: 4px solid #2d6f81;
}

thead tr th {
    font-weight: 700;
    height: 32px;
}

tbody tr {
    cursor: pointer;
}

tbody tr:nth-child(odd) {
    background: #ecf5f8;
}

tbody tr td {
    height: 1.875rem;
    font-weight: 300;
}


.cell {
    font-size: 14px;
    border: 1px solid #2d6f81;
    vertical-align: middle;
    text-align: center;
}

.cell.cell-num {
    width: 3.75rem;
}

.cell.cell-title {
    width: 392px;
}

tbody .cell.cell-title {
    padding-left: 0.5rem;
    text-align: left;
}

.cell.cell-company {
    width: 11.5rem;
}

.cell.cell-date {
    width: 9.75rem;
}

.cell.cell-visibility {
    width: 6rem;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagi-btn {
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.5rem;
    cursor: pointer;
}

/* 현재 페이지 */
.pagi-btn.active {
    font-weight: 700;
    border-bottom: 1px solid #000;
}

/* ----------------------------- modal */
.modal-container {
    width: 37.5rem;
    height: 40.5rem;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
    overflow: hidden;
}

.modal-container .page-title {
    position: relative;
}

.modal-close-button:hover{
    cursor: pointer;
}

/* Form Layout */
.registration-form {
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
    gap: 1rem;
}

.admin-form-group {
    display: flex;
    gap: 0.5rem;
}

.admin-form-group.form-gruop-textarea {
    flex-direction: column;
    height: 8.5rem;
    margin-top: 0.5rem;
}

.form-label {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    min-width: 5.75rem;
    text-align: left;
    font-weight: 700;
}

.form-label span {
    margin-left: 0.25rem;
    color: red;
}

.input-wrapper {
    flex-grow: 1;
    padding: 4px;
    border: 2px solid #409CB5;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    height: 2.375rem;
    overflow: hidden;
}

.form-input,
.form-input-file,
.form-textarea {
    width: 100%;
    font-size: 0.875rem;
    border: none;
}

.form-input:focus,
.form-textarea:focus,
.form-input-file:focus {
    outline: none;
}

.form-input.form-input-small {
    width: 160px;
    font-size: 0.875rem;
}

.form-textarea {
    height: 100px;
    resize: vertical;
}

.radio-group {
    display: flex;
    align-items: center;
}

.form-radio{

    margin-right: 5px;
}

.radio-label {
    display: block;
    line-height: 2rem;
    margin-right: 15px;
    font-size: 0.9em;
}

/* ------------------------------------ Form Action btns */
.form-actions-regist,
.form-actions-edit {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    justify-content: center;
    position: relative;
}

.btn-modal {
    width: 7.75rem;
    padding: 0.625rem 0;
    font-size: 0.875rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.btn-submit {
    background-color: #409CB5;
    color: #fff;
}

.btn-cancel {
    background-color: #9CA3AF;
    color: #fff;
}

.btn-delete {
    /*position: absolute;*/
    left: 0;
    background-color: #991B1B;
    color: #fff;
}

.btn-submit:hover {
    background-color: #2D6F81;
}

.btn-cancel:hover {
    background-color: #6B7280;
}

.btn-delete:hover {
    background-color: #DC2626;
}

/* MacOS 스크롤바 커스텀 (선택 사항) */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: #2D6F81;
    border-radius: 6px;
}

::-webkit-scrollbar-track {
    background-color: #F0F0F0;
}
