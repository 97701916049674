.subtitle.sec3__subtitle {
    align-items: center;
}

.subtitle.sec3__subtitle .desc-text {
    text-align: center;
}

.sec3 .section__inner {
    height: 100%;
}

.swiper.section3-slider {
    width: 100%;
    margin-top: 2rem;
    padding-top: 2rem;
    height: 56vh;
}

.section3-slider .swiper-wrapper {
    width: 100%;
}

.section3-slider .swiper-wrapper .swiper-slide {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
}

.machine-img {
    width: 38%;
    height: auto;
}

.info-table {
    width: 30vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.info-table .info-table-top {
    margin-bottom: 1.4rem;
}

.info-table .info-table-top .info-text {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.8rem 0;
    text-align: center;
}

.info-table .info-table-top .info-text.info-text-1 {
    border-bottom: 0.125rem solid #000;
}

.info-table .info-table-top .info-text.machine-name {
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: capitalize;
}

.info-table .info-table-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
}

.info-table .info-table-bottom .info-row {
    display: flex;
    gap: 16px;
}

.info-table .info-table-bottom .info-row > * {
    font-size: 1rem;
    padding: 12px 0;
}
.info-table .info-table-bottom .info-row .menu {
    width: 108px;
    border-right: 0.0625rem solid #000;
    font-weight: 600;
}

.info-table .info-table-bottom .info-row .info-text {
    flex-grow: 1;
}

.section3-slider .swiper-pagination {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.section3-slider .swiper-pagination .swiper-pagination-bullet {
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    font-size: 1.125rem;
    color: transparent;
    background-color: #7fbdcd;
}

.section3-slider
.swiper-pagination
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #409cb5;
}

.section3-slider .swiper-button-next,
.section3-slider .swiper-button-prev {
    color: #333;
}

.section3-slider .swiper-button-next::after,
.section3-slider .swiper-button-prev::after {
    font-size: 2.5rem;
}
/* -----------------------------태블릿 가로  */
@media all and (min-width: 1024px) and (max-width: 1440px) {
}

/* -----------------------------태블릿 세로  */
@media all and (min-width: 753px) and (max-width: 1023px) {
    .swiper.section3-slider {
        flex-grow: 1;
    }
    .section3-slider .swiper-wrapper .swiper-slide {
        padding-top: 4rem;
        flex-direction: column;
    }

    .machine-img {
        width: 40%;
        height: auto;
    }
    .info-table {
        width: 70%;
        justify-content: flex-start;
    }
    .info-table .info-table-top {
    }
    .info-table .info-table-top .info-text {
    }
    .info-table .info-table-top .info-text.info-text-1 {
        font-size: 1.5rem;
    }
    .info-table .info-table-top .info-text.machine-name {
        font-size: 2.25rem;
    }

    .info-table .info-table-bottom {
        flex-grow: 1;
        justify-content: space-evenly;
    }
    .info-table .info-table-bottom .info-row {
    }
    .info-table .info-table-bottom .info-row > * {
        font-size: 1.375rem;
    }
    .info-table .info-table-bottom .info-row .menu {
        width: 10rem;
    }
    .info-table .info-table-bottom .info-row .info-text {
    }
}

/* 모바일 */
@media all and (max-width: 752px) {
    .sec3 .inner {
    }
    .swiper.section3-slider {
        margin-top: 0;
        flex-grow: 1;
    }
    .section3-slider .swiper-wrapper .swiper-slide {
        padding-top: 0;
        flex-direction: column;
    }

    .machine-img {
        width: 46%;
        height: auto;
    }
    .info-table {
        width: 90%;
        justify-content: flex-start;
    }
    .info-table .info-table-top {
        margin-bottom: 0;
    }
    .info-table .info-table-top .info-text {
    }
    .info-table .info-table-top .info-text.info-text-1 {
        font-size: 12px;
    }
    .info-table .info-table-top .info-text.machine-name {
        font-size: 14px;
    }

    .info-table .info-table-bottom {
        flex-grow: 1;
        justify-content: space-evenly;
    }
    .info-table .info-table-bottom .info-row {
        gap: 4px;
    }
    .info-table .info-table-bottom .info-row > * {
        font-size: 12px;
    }
    .info-table .info-table-bottom .info-row .menu {
        width: 76px;
    }
    .info-table .info-table-bottom .info-row .info-text {
    }

    .section3-slider .swiper-button-next,
    .section3-slider .swiper-button-prev {
        color: #333;
        display: none;
    }

    .section3-slider .swiper-button-next::after,
    .section3-slider .swiper-button-prev::after {
        display: none;
    }
}