/* 데스크탑 */
.sec2__inner {
    gap: 3vh;
}
.sec2__inner > * {
    height: auto;
}
.sec2__subtitle {
    width: 100%;
}

.sec2_desc_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.sec2_desc {
    width: fit-content;
}

.sec2_desc.sec2_desc_maintxt {
    width: 40vw;
    height: auto;
}

.sec2_desc p,
.sec2_desc span {
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 160%;
}

.sec2_desc span {
    width: fit-content;
    display: inline;
}

.sec2_sub_text {
    font-size: 1.125rem;
    width: 25rem;
    color: #555;
    font-weight: 600;
    line-height: 150%;
}
/* description 2 */
.sec2_wide,
.sec2_wide span {
    line-height: 160%;
    font-size: 1.5rem;
    font-weight: 600;
    width: 100%;
}
@media all and (min-width: 1920px) {
    .sec2_sub_text {
        width: 20vw;
        font-size: 1.2vw;
    }
    .sec2_desc p,
    .sec2_desc span {
        font-size: 3rem;
    }
    /* description 2 */
    .sec2_wide,
    .sec2_wide span {
        line-height: 160%;
        font-size: 2rem;
        font-weight: 600;
        width: 100%;
    }
}

/* 모바일 화면용 */
.sec2_desc.sec2_small {
    display: none;
}

.sec2_desc.sec2_small,
.sec2_desc.sec2_small span {
    font-size: 1rem;
}

.sec2_desc.sec2_small span {
    font-weight: 700;
}

span.deco-navy {
    color: #2e4f89;
}

span.deco-orange {
    color: #ea8d36;
}

.sec2__contents-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.8vh;
}

.sec2_slide_container {
    min-height: 25rem;
    height: 40vh;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

/* 슬라이드 */

.sec2_slide {
    height: 100%;
    border: 0.125rem solid #2e4f89;
    width: 11.25rem;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;
    transition: all 1s ease;
    flex-grow: 1;
}

.sec2_slide .slide__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;
    gap: 1.5rem;
}

.sec2_slide .slide__top .service {
    font-size: 0.875rem;
}

.sec2_slide .slide__top .service.service_num {
    width: 8.25rem;
    border-radius: 1.25rem;
    border: 0.125rem solid #2e4f89;
    background: linear-gradient(90deg, #2e4f89 0%, #555 100%);
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    text-align: center;
    padding: 0.625rem 0;
}

.sec2_slide .slide__top .service.service_title {
    color: #2e4f89;
    font-weight: 700;
    font-size: calc(0.75rem + 0.5vw);
}

.sec2_slide .slide__bottom {
    flex-grow: 1;
    height: 16.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.sec2_slide .slide__bottom .service__feature {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.sec2_slide .slide__bottom .service__feature .feature__name {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
}

.sec2_slide .slide__bottom .service__feature .feature__name img {
    width: 1.5rem;
}

.sec2_slide .slide__bottom .service__feature .feature__name p {
    font-size: calc(0.45rem + 0.5vw);
    font-weight: 500;
    color: #2e4f89;
}

.sec2_slide .slide__bottom .service__feature .feature__desc {
    display: none;
}

/* 활성화 된 슬라이드 */

.sec2_slide.curr {
    flex-grow: 3;
    background: linear-gradient(90deg, #2e4f89 0%, #555 100%);
    box-shadow: 0.125rem 0.25rem 0.5rem 0rem rgba(0, 0, 0, 0.5);
    padding-left: calc(1.85rem + 0.5vw);
}

.sec2_slide.curr .slide__top {
    align-items: flex-start;
}

.sec2_slide.curr > .slide__top .service.service_num {
    background: #fff;
    color: #2e4f89;
}

.sec2_slide.curr .slide__top .service.service_title {
    text-wrap: nowrap;
    color: #fff;
    font-size: 2.5rem;
}

.sec2_slide.curr .slide__bottom {
    width: 100%;
    padding-top: 2rem;
    flex-direction: row;
    gap: calc(1rem + 0.5vw);
}

.sec2_slide.curr .slide__bottom .service__feature {
    min-width: 12vw;
    width: 30%;
    height: fit-content;
    align-items: flex-start;
}

.sec2_slide.curr .slide__bottom .service__feature .feature__name {
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    min-height: fit-content;
    height: 5vh;
}

.sec2_slide.curr .slide__bottom .service__feature .feature__name p {
    color: #fff;
    font-size: 1.25rem;
}

.sec2_slide.curr .slide__bottom .service__feature .feature__desc {
    font-size: 1.125rem;
    min-width: 218px;
    width: 88%;
    opacity: 1;
    line-height: 160%;
    padding-left: 1.75rem;
    display: block;
    color: #dcdcdc;
    font-weight: 400;
}

/* slide nav */

.sec2_slide_nav {
    display: flex;
    gap: 0.5rem;
    width: fit-content;
}

.sec2_slide_nav .nav-bullet {
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    border: 0.0625rem solid #2e4f89;
    cursor: pointer;
}

.sec2_slide_nav .nav-bullet.nav-bullet-active {
    background-color: #2e4f89;
}

/* -----------------------------태블릿 가로  */
@media all and (min-width: 1024px) and (max-width: 1440px) {
    .sec2 {
    }
    .inner.sec2__inner {
        transform: scale(.85);
    }
    .sec2_desc_box {
        justify-content: space-between;
    }

    .sec2_desc.sec2_desc_maintxt {
        width: 50vw;
        max-height: min-content;
    }

    .sec2_sub_text {
        width: 24vw;
    }

    .sec2_slide.curr {
        padding-left: 2vh;
    }

    .sec2_slide.curr .slide__top {
        width: 100%;
    }

    .sec2_slide.curr .slide__bottom {
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5vh;
        padding-top: 0;
        min-height: auto;
    }

    .sec2_slide.curr .slide__bottom .service__feature {
        height: fit-content;
        width: 100%;
        gap: 0;
    }

    .sec2_slide.curr .slide__bottom .service__feature .feature__name {
        height: 2rem;
    }
    /* 수정 */
    .sec2_slide.curr .slide__bottom .service__feature .feature__name p {
        font-size: 1.25rem;
    }

    /* 수정 */
    .sec2_slide.curr .slide__bottom .service__feature .feature__desc {
        opacity: 1;
        width: 100%;
        font-size: 1rem;
    }
}

/* -----------------------------태블릿 세로  */

@media all and (min-width: 753px) and (max-width: 1023px) {
    .inner.section__inner {
        height: 100vh;
        justify-content: flex-start;
        padding: 1.5rem;
        gap: 0;
    }

    .sec2__subtitle {
        padding: 0;
    }

    .sec2_desc_box,
    .sec2_desc.sec2_small {
        width: 100%;
    }

    .sec2_sub_text {
        display: none;
    }
    .sec2_desc.sec2_desc_maintxt {
        width: 80vw;
    }

    /* 모바일 화면용 */
    .sec2_desc.sec2_small {
        display: block;
        font-weight: 600;
        padding: 0.5rem 0;
    }

    .sec2_desc.sec2_small,
    .sec2_desc.sec2_small span {
        font-size: 1.375rem;
        line-height: 160%;
    }

    /* description 2 */
    .sec2_wide {
        display: none;
    }

    .sec2__contents-wrapper {
        min-width: 48vh;
        height: 60vh;
        padding-bottom: .5rem;
    }

    .sec2_slide_container {
        width: 100%;
        height: 100%;
        flex-direction: column;
        flex-grow: 1;
    }

    /* 슬라이드 */
    .sec2_slide {
        width: 100%;
        height: fit-content;
        border: 0.0625rem solid #888;
        box-shadow: none;
        flex-grow: 0;
    }

    .sec2_slide .slide__top {
        align-items: flex-start;
        margin: 0;
        height: 4rem;
    }

    .sec2_slide .slide__top .service {
        padding-left: 0.5rem;
        transition: all 0.5s;
    }

    .sec2_slide .slide__top .service.service_num {
        display: none;
    }

    .sec2_slide .slide__top .service.service_title {
        font-size: 1.4rem;
        color: #888;
        font-weight: 600;
    }

    .sec2_slide .slide__bottom {
        height: 0;
    }

    .sec2_slide .slide__bottom .service__feature {
        height: 0;
        opacity: 0;
    }

    /* 활성화 된 슬라이드 */
    .sec2_slide.curr {
        padding: 0;
        background: #fff;
        border: none;
        box-shadow: none;
    }

    .sec2_slide.curr .slide__top {
        background: linear-gradient(90deg, #2e4f89 0%, #555 100%);
        border: 0.0625rem solid #2e4f89;
        box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
        height: 5rem;
    }

    .sec2_slide.curr .slide__top .service {
        transform: translateX(1.25rem);
    }

    .sec2_slide.curr .slide__top .service.service_title {
        font-size: 1.48rem;
    }

    .sec2_slide.curr .slide__bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0.5rem 0;
    }

    .sec2_slide.curr .slide__bottom .service__feature {
        opacity: 1;
        width: 100%;
        padding: 0 1rem;
    }

    .sec2_slide.curr .slide__bottom .service__feature .feature__name {
    }
    .sec2_slide.curr .slide__bottom .service__feature .feature__name p {
        color: #2e4f89;
        font-size: 1.4em;
        font-weight: 700;
    }

    .sec2_slide.curr .slide__bottom .service__feature .feature__desc {
        color: #444;
    }

    /* slide nav */
    .sec2_slide_nav {
        display: none;
    }
}

@media all and (max-width: 752px) {
    .inner.section__inner {
        height: 100%;
        justify-content: flex-start;
        padding: 0 1.6rem;
        gap: 0;
    }


    .sec2__subtitle {
        padding: 0;
    }


    .sec2_desc_box,
    .sec2_desc.sec2_small {
        width: 100%;
    }

    .sec2_sub_text {
        display: none;
    }
    .sec2_desc.sec2_desc_maintxt {
        width: 80vw;
    }
    .sec2_desc.sec2_desc_maintxt p , .sec2_desc.sec2_desc_maintxt p > * {
        font-size: 1.125rem;
    }

    /* 모바일 화면용 */
    .sec2_desc.sec2_small {
        display: block;
        font-weight: 600;
        padding: 0.5rem 0;
    }

    .sec2_desc.sec2_small,
    .sec2_desc.sec2_small span {
        font-size: 1rem;
        line-height: 160%;
    }

    /* description 2 */
    .sec2_wide {
        display: none;
    }

    .sec2__contents-wrapper {
        width: 100%;
        height: 40vh;
    }

    .sec2_slide_container {
        min-height: fit-content;
        height: 100%;
        /* background-color: salmon; */
        flex-direction: column;
    }

    /* 슬라이드 */
    .sec2_slide {
        width: 100%;
        height: fit-content;
        border: 0.0625rem solid #888;
        box-shadow: none;
        flex-grow: 0;
    }

    .sec2_slide .slide__top {
        align-items: flex-start;
        margin: 0;
        height: 2rem;
        padding: 0;
    }

    .sec2_slide .slide__top .service {
        padding-left: 0.5rem;
        transition: all 0.5s;
    }

    .sec2_slide .slide__top .service.service_num {
        display: none;
    }

    .sec2_slide .slide__top .service.service_title {
        font-size: .8rem;
        color: #888;
        font-weight: 600;
    }

    .sec2_slide .slide__bottom {
        height: 0;
    }

    .sec2_slide .slide__bottom .service__feature {
        height: 0;
        opacity: 0;
    }

    /* 활성화 된 슬라이드 */
    .sec2_slide.curr {
        padding: 0;
        background: #fff;
        border: none;
        box-shadow: none;
        flex-grow: 1;
        max-height: 12.5rem;
    }

    .sec2_slide.curr .slide__top {
        background: linear-gradient(90deg, #2e4f89 0%, #555 100%);
        border: 0.0625rem solid #2e4f89;
        box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
        height: 2rem;
    }

    .sec2_slide.curr .slide__top .service {
        transform: translateX(1.25rem);
    }

    .sec2_slide.curr .slide__top .service.service_title {
        font-size: 1rem;
    }

    .sec2_slide.curr .slide__bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0.5rem 0;
    }

    .sec2_slide.curr .slide__bottom .service__feature {
        opacity: 1;
        width: 100%;
        padding: 0 1rem;
    }


    .sec2_slide.curr .slide__bottom .service__feature .feature__name p {
        color: #2e4f89;
        font-size: .8em;
        font-weight: 700;
    }

    .sec2_slide.curr .slide__bottom .service__feature .feature__desc {
        display: none;
    }

    /* slide nav */
    .sec2_slide_nav {
        display: none;
    }
}


/* 241129 수정사항 */

.sec2_slide {
    flex-grow: 0;
    min-width: 13.75rem;
}