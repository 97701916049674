@charset "UTF-8";

.sub-page-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
}

.sub-inner.sub-sec4-sub__inner {
    width: 90vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    margin: auto;
    justify-content: space-evenly;
}

.sub-subtitle.sub-sec4-sub__subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 1.5rem;
}

.sub-subtitle.sub-sec4-sub__subtitle .sub-page-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19.25rem;
}

.sub-subtitle.sub-sec4-sub__subtitle .sub-page-title img {
    width: 5rem;
}

.sub-subtitle.sub-sec4-sub__subtitle .sub-page-title .sub-name {
    font-size: 3rem;
    font-weight: 700;
}

.sub-subtitle.sub-sec4-sub__subtitle .sub-gnb__btn {
    position: absolute;
    left: 0;
    width: 12.5rem;
    height: 3rem;
    background-color: #409cb5;
    border-radius: 1rem;
    color: #fff;
    font-weight: 600;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.sub-sec4-sub__contents {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 3rem;
}

.sub-sec4-sub__contents .sub-review-wrapper {
    min-height: 38rem;
    height: 38rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem 1.5rem;
    align-content: center;
    justify-content: center;
}

.sub-review-wrapper .sub-review-card {
    min-width: 12.5rem;
    width: 12.5rem;
    max-width: 12.5rem;
    height: fit-content;
    border-radius: 1.5rem;
    background: #fff;
    box-shadow: 0rem 0.25rem 0.625rem 0rem rgba(0, 0, 0, 0.4);
    display: flex;
    padding: 0.625rem;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.sub-review-card .sub-review__thumbnail {
    width: 100%;
    min-height: 7.5rem;
    text-indent: -6.25rem;
    overflow: hidden;
    border-radius: 1rem;
    border: 1px solid #ccc;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.sub-review-card .sub-review__bottom .sub-review__company {
    height: 1.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
}

.sub-review-card .sub-review__bottom .sub-review__company .sub-company {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.sub-review-card .sub-review__bottom .sub-review__company img.sub-user {
    width: 1.25rem;
}

.sub-review-card .sub-review__bottom .sub-review__company p.sub-company-name {
    font-size: 1rem;
    font-weight: 600;
}

.sub-review-card .sub-review__bottom .sub-review__company img.sub-heart {
    width: 1.25rem;
}

.sub-review-card .sub-review__bottom .sub-review-content {
    flex-grow: 1;
}

.sub-review-card .sub-review__bottom .sub-review-content::-webkit-scrollbar {
    display: none;
}

.sub-review-card .sub-review__bottom .sub-review-content .sub-review__title {
    height: 2.5rem;
    font-size: 1.125rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.sub-review-card .sub-review__bottom .sub-review-content .sub-review__context {
    height: 2.75rem;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 160%;
    overflow: hidden;
}

/* pagination */
.sub-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-pagi-btn {
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    cursor: pointer;
}

/* 현재 페이지 */
.sub-pagi-btn.active {
    font-weight: 700;
    border-bottom: 0.0625rem solid #000;
}

.sub-review__bottom {
    width: 100%;
}

.sub-sec4-noData {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 500;
}
/* 태블릿 가로 */
@media all and (min-width: 1024px) and (max-width: 1440px) {
    .sub-sec4-sub__contents .sub-review-wrapper {
        min-height: fit-content;
        height: fit-content;
    }
    .pagination{
        transform: scale(1.2);
    }
}

/* 태블릿 세로 */
@media all and (min-width: 753px) and (max-width: 1023px) {
    .sub-sec4-sub__contents .sub-review-wrapper {
        width: 100%;
    }
    .pagination{
        transform: scale(1.5);
    }
}

/* 모바일 */
@media all and (max-width: 752px) {
    .sec4-sub .sub-inner {
        height: 100vh;
        gap: 2vh;
    }
    .sub-sec4-sub__contents {
        height: 100%;
        padding-bottom: 2rem;
    }
    .sub-subtitle.sub-sec4-sub__subtitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-top: 0rem;
    }

    .sub-subtitle.sub-sec4-sub__subtitle .sub-page-title .sub-name {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .sub-inner.sub-sec4-sub__inner {
        width: 90%;
        gap: 1rem;
    }

    .sub-subtitle.sub-sec4-sub__subtitle {
        padding-left: 30%;
    }

    .sub-sec4-sub__contents .sub-review-wrapper {
        min-height: auto;
        height: 100%;
    }


    .sub-subtitle.sub-sec4-sub__subtitle .sub-gnb__btn {
        width: 5.2rem;
        font-size: 16px;
        border-radius: 0.5rem;
    }

    .sub-review-wrapper .sub-review-card {
        min-width:48vw;
        width: 48vw;
        max-width:48vw;
        padding: 0.5rem;
    }

    .sub-review-card .sub-review__thumbnail {
        min-height: 6rem;
        height: 6rem;
    }

    .sub-review-card .sub-review__bottom .sub-review__company p.sub-company-name {
        font-size: 0.875rem;
    }

    .sub-review-card .sub-review__bottom .sub-review-content .sub-review__title {
        padding: 0;
        font-size: 0.875rem;
        height: 1.5rem;
    }

    .sub-review-card
    .sub-review__bottom
    .sub-review-content
    .sub-review__context {
        height: 1.5rem;
        font-size: 0.875rem;
    }
}