header {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 4.75rem;
    background: #fff;
    box-shadow: 0rem 0.25rem 0.625rem 0rem rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

header * {
    transition: all 0.5s;
}

.inner.header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-logo {
    width: 10.25rem;
    height: auto;
}

.gnb {
    width: 12.5rem;
    height: 4rem;
}

.gnb__btn {
    width: 100%;
    height: 100%;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6b7280;
    font-size: 1.15rem;
    text-align: center;
    line-height: 130%;
}

.gnb__btn.gnb--normal:hover {
    color: #7fbdcd;
}

.gnb__btn.gnb--normal.active {
    color: #409cb5;
    font-weight: 700;
    text-decoration: underline;
}

.gnb__btn.btn-regist {
    background-color: #409cb5;
    border-radius: 1rem;
    color: #fff;
    font-weight: 600;
    padding: 0 0.125rem;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    background: #409cb5;
}

#fp-nav ul li .fp-tooltip {
    color: #409cb5;
    font-weight: 700;
}
/* 태블릿 가로 */
@media all and (min-width: 1024px) and (max-width: 1440px) {

}
/* 태블릿 세로 */
@media all and (min-width: 753px) and (max-width: 1023px) {}
/* 모바일 */
@media all and (max-width: 752px) {
    /* 수정 */
    .main-logo {
        width: 30vw;
        height: auto;
    }
}
