.swiper.mainSwiper,
.swiper.mainSwiper .swiper-wrapper,
.swiper.mainSwiper .swiper-wrapper .swiper-slide {
    width: 100%;
    height: calc(100vh - 4.75rem);
    position: relative;
}

.swiper.mainSwiper .swiper-wrapper .swiper-slide::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.swiper.mainSwiper .swiper-wrapper .swiper-slide .slide-contents {
    width: 100%;
    height: 100%;
}

.swiper.mainSwiper .swiper-wrapper .swiper-slide .slide-text-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.swiper.mainSwiper .slide-text-box .text-box {
    margin-bottom: 1.5rem;
}

.swiper.mainSwiper .slide-text-box .text-box__top {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    line-height: 160%;
}

.swiper.mainSwiper .slide-text-box .text-box__bottom {
    color: #fff;
    font-size: 1.25rem;
    width: 33.75rem;
    font-weight: 600;
    line-height: 160%;
}

.swiper.mainSwiper .swiper-pagination {
    left: calc(((236) / 1920) * 100%);
    bottom: 38%;
}

.swiper.mainSwiper .swiper-pagination .swiper-pagination-bullet {
    margin: 0 0.5rem;
}
.swiper-pagination-bullet-active {
    background: #ffffff;
}
.swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.95);
}

.sec1 .main-slide-text-inner {
    width: 86%;
}

.swiper.mainSwiper .slide-text-box .text-box__top {
    font-size: 1.75rem;
}

.swiper.mainSwiper .slide-text-box .text-box__bottom {
    width: 100%;
    font-size: 1.25rem;
}

.swiper.mainSwiper .swiper-pagination {
    left: -35%;
    bottom: 41%;
}

#fp-nav.fp-right {
    right: 0.3125rem;
}

/**/
.swiper-button-next,
.swiper-button-prev {
    width: 7rem; /* 버튼 크기를 조정 */
}

/*22*/
.slide-contents {
    position: relative; /* 비디오와 텍스트를 겹치게 하기 위해 상대 위치 설정 */
    width: 100%;
    height: 360px; /* 슬라이드 높이와 동일하게 설정 */
    overflow: hidden; /* 비디오가 영역을 넘어가지 않도록 설정 */
}

.background-video {
    position: absolute; /* 비디오를 슬라이드의 전체 공간을 차지하도록 설정 */
    top: 50%;
    left: 50%;
    width: 100%; /* 슬라이드의 너비에 맞춤 */
    height: auto; /* 비율 유지 */
    transform: translate(-50%, -50%); /* 비디오 중앙 정렬 */
    z-index: -1; /* 비디오를 배경으로 설정 */
}

.slide-text-box {
    position: relative; /* 텍스트가 비디오 위에 표시되도록 설정 */
    z-index: 1; /* 텍스트를 비디오 위에 표시 */
}

/* 여기서부터 추가 241129 --------------------------------------------------------------------------- */
.swiper.mainSwiper .swiper-button-next,
.swiper.mainSwiper .swiper-button-prev {
    color: #fff;
}

/* 태블릿 가로 */
@media all and (min-width: 1024px) and (max-width: 1440px) {
    .swiper.mainSwiper {
        background-color: #000;
    }
    .background-video {
        width: 110vw;
        height: auto;
    }
    .swiper.mainSwiper .swiper-pagination {
        left: -34vw;
        bottom: 34vh;
    }
}
/* 태블릿 세로 */
@media all and (min-width: 753px) and (max-width: 1023px) {
    .swiper.mainSwiper .swiper-button-next,
    .swiper.mainSwiper .swiper-button-prev {
        display: none;
    }
    .swiper.mainSwiper .swiper-pagination {
        left: -32vw;
    }
    .background-video {
        width: auto;
        height: 100vh;
    }
}
/* 모바일 */
@media all and (max-width: 752px) {
    .swiper.mainSwiper .swiper-button-next,
    .swiper.mainSwiper .swiper-button-prev {
        display: none;
    }
    .background-video {
        width: auto;
        height: 100vh;
    }
    .swiper.mainSwiper div.swiper-pagination {
        left: -24vw;
        bottom: 20vh;
    }
    .swiper.mainSwiper .slide-text-box p.text-box__top {
        font-size: 1.6rem;
    }
    /* 수정 */
    .swiper.mainSwiper .slide-text-box p.text-box__bottom {
        font-size: 0.9rem;
    }
}

/* 모달 컨텐츠 수정 */
@media all and (min-width: 1024px) and (max-width: 1440px),
all and (min-width: 753px) and (max-width: 1023px),
all and (max-width: 752px) {
    .modal.modal-regist .modal-content-wrapper {
        width: 100%;
    }
}

/* 241129 끝 --------------------------------------------------------------------------- */
