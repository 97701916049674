@charset "UTF-8";

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

.modal {
    width: 37.5rem;
    height: 47.25rem;
    overflow: hidden;
    border-radius: 1rem;
    background: #fff;
    box-shadow: 0rem 0.25rem 0.625rem 0rem rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    margin: auto;
}

.modal-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.modal__title {
    width: 100%;
    display: flex;
    background-color: #7fbdcd;
    align-items: center;
    justify-content: space-between;
    height: 2.5rem;
    padding: 0 0.5rem;
}

.modal__title .modal-close {
    cursor: pointer;
}

.modal__title .modal-name {
    color: #fff;
    font-size: 1.125rem;
    font-weight: 600;
}

.modal__contents {
    padding: 1rem 2rem;
    width: 100%;
}

.modal__contents .modal__note {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #6b7280;
    gap: 0.1875rem;
    font-size: 0.75rem;
}

.modal__contents .modal__note span {
    color: red;
    height: 100%;
}

/* 신청할때 나오는 입력폼 컨텐츠 */

.modal__contents {
    margin-top: 0.5rem;
}

.form-group {
    width: 100%;
}

.form-group label.form-name {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 1rem;
    font-weight: 600;
    padding: 0 0.25rem;
    display: flex;
    position: relative;
}

.context-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.context-group span.char-count {
    display: block;
    height: auto;
    font-size: 1rem;
    font-weight: 600;
    color: #6b7280;
}

.form-group label.essential::after {
    content: "*";
    line-height: 120%;
    font-size: 0.875rem;
    line-height: 180%;
    padding-right: 0.25rem;
    color: red;
    display: absolute;
    width: auto;
}

.form-group .drop-down-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.form-group .drop-down-option p {
    font-weight: 400;
    color: #d1d5db;
    font-size: 0.875rem;
}

.disabled {
    display: none;
}

.form-group .option-group {
    position: absolute;
    background-color: #fff;
    width: 33.375rem;
    border-radius: 0.5rem;
    border: 0.125rem solid #409cb5;
    overflow: hidden;
    padding: 0.25rem;
    z-index: 8;
}

.form-group .option-group li {
    font-size: 0.875rem;
    padding: 0.5rem;
    transition: background-color 0.3s ease;
}

.form-group .option-group li:hover {
    font-weight: 600;
    background-color: #a5d2dd;
}

.form-group .option-group li.option-item.selected {
    background-color: #409cb5;
    color: #fff;
}

input,
input:focus,
textarea,
textarea:focus {
    outline: none;
    box-shadow: none;
}

/* 플레이스 홀더 관련 css */
::placeholder {
    color: #999;
    font-size: 0.875rem;
    opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
    font-size: 0.875rem; /* Edge 12 -18 */
    color: #999;
}

.chevron-down {
    transition: transform 0.3s ease;
}

.open .chevron-down {
    transform: rotate(180deg);
}

.form-group .user-input {
    width: 100%;
    height: 2rem;
    min-height: 2rem;
    border-radius: 0.375rem;
    border: 0.125rem solid #409cb5;
    overflow: hidden;
    padding: 0 0.25rem;
    background-color: #fff;
}

.form-group .user-input.textarea-type {
    height: 5.75rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    height: 100%;
    background: #fff;
    border: none;
    line-height: 140%;
    font-weight: 300;
}

.form-group textarea {
    width: 100%;
    height: 100%;
    resize: none;
}

/* 첨부파일 관련 */
.custom-file-upload-btn {
    display: inline-block;
    padding: 0.625rem 1.25rem;
    background-color: #7fbdcd;
    color: white;
    border: none;
    border-radius: 0.3125rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.custom-file-upload-btn:hover {
    background-color: #409cb5;
}

.user-input.file {
    height: 5rem;
    padding: 0.25rem;
    display: flex;
}

.user-input.file input[type="file"] {
    display: none;
}

.file-list {
    margin-top: 0rem;
    flex-grow: 1;
}

.file-list ul {
    height: 100%;
    padding-left: 0.625rem;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.file-list li {
    margin-bottom: 0.3125rem;
    font-size: 0.875rem;
}

/* 삭제버튼 */
.remove-file-btn {
    background: none;
    border: none;
    color: #999;
    font-size: 1.125rem;
    cursor: pointer;
    padding: 0;
    margin-left: 0.625rem;
}

.remove-file-btn:hover {
    color: #555; /* 호버 시 색상 변경 */
}

.form-group.checkbox-group {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
}

.form-group.checkbox-group input {
    width: auto;
}

.form-group.checkbox-group label {
    font-size: 0.875rem;
    font-weight: 600;
    color: #6b7280;
}

.form-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.3125rem;
}

/* 신청버튼 */
.modal .btn-modal-regist {
    cursor: pointer;
    display: block;
    text-align: center;
    padding: 0.625rem 0;
    width: 12.5rem;
    border-radius: 1rem;
    border: none;
    background: #409cb5;
    font-size: 1.25rem;
    color: #fff;
    font-weight: 700;
}

/* 신청 완료 후 나오는 확인 페이지 */

.complete-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 44.75rem;
    gap: 1.5rem;
}

.complete-contents img {
    width: 5.125rem;
}

.complete-contents .complete-text {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 160%;
}

.alert-message {
    width: 25rem;
    height: 12.5rem;
}

.alert-message .message-contents {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
    gap: 1.5rem;
}

.alert-message .message-contents .message-text {
    font-size: 1.125rem;
    font-weight: 600;
}

/* ------------------------리뷰모달 */
.modal-content-wrapper .reviewSwiper {
    width: 100%;
    height: 18rem;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.reviewSwiper .swiper-wrapper {
    height: 100%;
}

.reviewSwiper .swiper-slide {
    width: 100%;
    height: 25rem;
    text-align: center;
    font-size: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviewSwiper .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: contain;
}

.reviewSwiper .swiper-pagination {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.reviewSwiper .swiper-pagination .swiper-pagination-bullet {
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    font-size: 1.125rem;
    color: transparent;
    background-color: #000;
}

.reviewSwiper
.swiper-pagination
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #409cb5;
}

.reviewSwiper .swiper-button-next::after,
.reviewSwiper .swiper-button-prev::after {
    font-size: 2rem;
}

.reviewSwiper .swiper-button-prev,
.reviewSwiper .swiper-button-next {
    color: #fff;
}
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.2em;
    z-index: 1000;
}
/* 태블릿 가로 */
@media all and (min-width: 1024px) and (max-width: 1440px) {
    .modal {
        width: 56vw;
        height: fit-content;
        max-height: 80vh;
    }
    .modal__contents {
        margin-top: 0;
        padding: 0.5rem;
    }

    .modal__contents form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem 1rem;
        justify-content: space-evenly;
    }
    .modal__contents form .form-group {
        max-width: 48%;
    }

    .modal__contents form .form-group.form-bottom {
        max-width: none;
        width: 100%;
        justify-content: flex-end;
    }
    .modal__contents form .form-group.form-bottom > * {
        width: 24%;
    }
}

/* 태블릿 세로 */
@media all and (min-width: 753px) and (max-width: 1023px) {
    .modal {
        width: fit-content;
        height: fit-content;
    }

    .modal__contents form {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .modal__contents form .form-group {
        width: 70vw;
    }

    .form-group .option-group {
        width: 70vw;
    }
    .modal__contents form .form-group label {
        font-size: 1.4vh;
    }

    ::placeholder,
    ::-ms-input-placeholder {
        font-size: 1.4vh;
    }
}

/* 모바일 */
@media all and (max-width: 752px) {
    .modal {
        width: fit-content;
        height: fit-content;
        max-width: 100vh;
        overflow: hidden;
        border-radius: 1rem;
        background: #fff;
        box-shadow: 0rem 0.25rem 0.625rem 0rem rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        margin: auto;
        transform: scale(0.95);
    }

    .form-group .user-input.textarea-type {
        height: 3.75rem;
    }

    .modal-review {
        width: 100vw;
        height: 37rem;
    }

    .modal .form-group {
        width: 80vw;
    }
    .form-group .option-group {
        width: 80vw;
    }
    .modal__contents {
        margin-top: 0;
        padding: 0.5rem 1.5rem;
    }

    .complete-contents img {
        width: 3.75rem;
    }

    .complete-contents .complete-text {
        font-size: 1.125rem;
    }

    .alert-message {
        width: 1rem;
    }
    .alert-message .message-contents .message-text {
        font-size: 1rem;
    }
    .modal .btn.btn-modal-regist {
        padding: 1rem 0;
        font-size: 1rem;
        margin-left: 6%;
    }
}

/* 수정 241127 */
.reviewSwiper .swiper-button-prev,
.reviewSwiper .swiper-button-next {
    color:#7fbdcd;
    text-shadow: -1px 1px 0px #fff;
}
/* 수정 241127 */
.reviewSwiper .swiper-button-next::after, .reviewSwiper .swiper-button-prev::after, .reviewSwiper .swiper-button-next::after, .reviewSwiper .swiper-button-prev::after {
    font-size: 2.5rem;
}

/* 수정 241127 */
::-webkit-scrollbar {
    width: 1rem;
}
/* 수정 241127 */
::-webkit-scrollbar-thumb {
    background-color: #2D6F81;
    border-radius: 100rem;
}
/* 수정 241127 */
::-webkit-scrollbar-track {
    background-color: transparent;
}

/* 수정 241127 */
.modal-review .review__details .review-content .review__title {
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
}

/* 태블릿 가로 241127 추가 */
@media all and (min-width: 1024px) and (max-width: 1440px) {
    .modal.modal-review {
        width: fit-content;
    }
    .modal-content-wrapper {
        flex-direction: row;
        width: 64vw;
        height: 100%;
    }
    .modal-content-wrapper .reviewSwiper {
        width: 100%;
        height: 100%;
    }


    .modal-review .review__details {
        width: 50%;
        max-height: 100%;
        min-height: 100%;
    }


    .modal-review .review__details .review-content {
        gap: .5rem;
    }

}

/* 태블릿 세로 241127 추가 */
@media all and (min-width: 753px) and (max-width: 1023px) {
    .modal.modal-review {
        width: fit-content;
        height: fit-content;
        top: 4vh;
    }
    .modal-content-wrapper {
        width: 60vw;
    }

    .modal-content-wrapper .reviewSwiper .swiper {
        height: 100%;
    }
    .modal-content-wrapper .reviewSwiper .swiper .swiper-slide {
        height: 100%;
    }
    .modal-content-wrapper .reviewSwiper .swiper .swiper-slide img {
        object-fit: contain;
    }
    .modal-review .review__details {
        width: 100%;
    }


    .modal-review .review__details .review-content {
        height: 40%;
        gap: .5rem;
    }

}
/* 모바일 241127 추가 */
@media all and (max-width: 752px) {
    .modal.modal-review {
        width: fit-content;
        height: fit-content;
        top: 6vh;
    }

    .modal-content-wrapper {
        width: 80vw;
        height: 100%;
    }


    .modal-content-wrapper .reviewSwiper .swiper {
        height: 100%;
    }

    .modal-content-wrapper .reviewSwiper .swiper .swiper-slide {
        height: 100%;
    }

    .modal-content-wrapper .reviewSwiper .swiper .swiper-slide img {
        object-fit: contain;
    }

    .modal-review .review__details {
        width: 100%;
        max-height: 42vh;
    }

    .modal-review .review__details .review-content {
        gap: .5rem;
        overflow: auto;
    }

    .modal-review .review__details .review-content .review__title {
        white-space: normal;
        overflow-wrap: break-word;
        word-break: break-word;
    }

    .modal-review .review__details .review-content .review__context {
        flex-grow: 0;
        height: 100%;
    }

}



/* ----------241129------------------ */

.reviewSwiper .swiper-button-prev {
    justify-content: left;
}
.reviewSwiper .swiper-button-next {
    justify-content: right;

}