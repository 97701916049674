/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}


@charset "UTF-8";

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");

/* http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
/*table,*/
caption,
/*tbody,*/
/*tfoot,*/
/*thead,*/
/*tr,*/
/*th,*/
/*td,*/
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-size: 1rem;
    display: block;
    text-decoration: none;
    box-sizing: border-box;
    color: #000;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* ----------------------------------------공통영역-------------------------------------------------------------- */

.play-regular {
    font-family: "Play", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.play-bold {
    font-family: "Play", sans-serif;
    font-weight: 700;
    font-style: normal;
}

* {
    font-family: "Pretendard";
    font-weight: 300;
    word-break: keep-all;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hidden {
    display: none;
}

.wrapper {
    width: 100%;
    height: 100vh;
}

.section {
    background-color: #fff;
}

.inner {
    width: 75%;
}

.inner.main-slide-text-inner {
    width: 75%;
}

.inner.section__inner {
    /* 이너 보더 여깄워여 */
    height: 90vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.subtitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
    word-break: keep-all;
}

.subtitle .page-name {
    font-size: 1.5rem;
    text-transform: capitalize;
    font-weight: 600;
    color: #2d6f81;
    line-height: 160%;
    padding: 0.6875rem 1.5rem;
    text-align: center;
    border-radius: 624.9375rem;
    background: #c4e0e8;
    width: fit-content;
}

.subtitle .desc-text {
    line-height: 160%;
}

.subtitle .desc-text.desc-text-1 {
    font-size: 2.5rem;
    font-weight: 700;
}

.subtitle .desc-text.desc-text-2 {
    font-size: 1.5rem;
    font-weight: 600;
}
