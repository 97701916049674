footer {
    width: 100%;
    height: 11.25rem;
    background-color: #222;
    padding: 2.25rem 0;
    bottom: 0;
    position: absolute;
    z-index: 100;
}

footer .inner.inner--footer {
    margin: auto;
    height: 6.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3.5rem;
}

footer img {
    mix-blend-mode: luminosity;
    width: auto;
    height: 2.75rem;
}

footer .footer-context {
    flex-grow: 1;
    height: 6.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

footer .footer-context ul li {
    font-size: 0.875rem;
    font-weight: 400;
    color: #fff;
    line-height: 160%;
}

footer .footer-context .copyright {
    font-size: 0.75rem;
    color: #d1d5db;
    text-transform: uppercase;
}

footer .family-site {
    width: 13.625rem;
    position: relative;
}

footer .family-site .family-site__btn {
    padding: 0.625rem 1rem;
    width: 13.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.0625rem solid #fff;
    cursor: pointer;
}

footer .family-site .family-site__btn p {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
}

footer .family-site .family-site__btn img {
    width: 1.25rem;
    height: 1.25rem;
}

footer .family-site ul.family-site__group {
    display: none;
    position: absolute;
    width: 13.625rem;
    bottom: 2.75rem;
    padding: 0.625rem;
    background-color: rgba(0, 0, 0, 0.9);
}

footer .family-site ul.family-site__group.show {
    display: block;
}

footer .family-site ul.family-site__group li a {
    color: #fff;
    font-size: 0.875rem;
    line-height: 2.1875rem;
    padding: 0rem 0.3125rem;
    border-bottom: solid 0.0625rem #ffffff;
    border-color: rgba(256, 256, 256, 0.1);
    cursor: pointer;
}

footer .family-site ul.family-site__group li a:hover {
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: 700;
}

.chevron-down {
    transition: transform 0.3s ease;
}

.family-site__group.show ~ .family-site__btn .chevron-down {
    transform: rotate(180deg);
}

/* 태블릿 가로 */
@media all and (min-width: 1024px) and (max-width: 1440px) {
    footer .inner.inner--footer {
        width: 90%;
        justify-content: space-evenly;
        padding-right: 10vw;
    }
    footer .footer-context {
        flex-grow: 0;
        width: fit-content;
    }
}
/* 태블릿 세로 */
@media all and (min-width: 753px) and (max-width: 1023px) {
    footer {
        padding: 1.5rem 0;
    }
    footer .inner.inner--footer {
        width: 92%;
        height: 100%;
        padding: 0;
        gap: 1rem;
        /* justify-content: space-around; */
        align-items: flex-start;
    }
    footer .footer-context {
        flex-grow: 0;
        width: fit-content;
    }
    footer .footer-context ul li, footer .footer-context .copyright {
        font-size: .7rem;
        line-height: 130%;
        font-weight: 300;
    }
}


/* 모바일 */
@media all and (max-width: 752px) {
    footer {
        width: 100vw;
        padding: 0.625rem 0;
    }

    footer img.footer-logo {
        height: 2.75rem;
    }
    footer .inner.inner--footer{
        width: 80%;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
    }
    footer .footer-context {
        flex-grow: 0;
        justify-content: center;
    }
    footer .footer-context ul li, footer .footer-context .copyright {
        font-size: 0.5rem;
        line-height: 130%;
        font-weight: 300;
    }
    footer .family-site {
        flex-grow: 1;
    }
    footer .family-site .family-site__btn {
        flex-grow: 1;
        padding: 0.5rem;
    }
    footer .family-site .family-site__btn p {
        font-size: 0.875rem;
    }
    footer img {
        height: 2rem;
    }

}
