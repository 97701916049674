.fab-regist {
    position: fixed;
    right: 2%;
    bottom: 5%;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    background: #409cb5 !important;
}

.fab {
    height: 3.75rem;
    z-index: 9999;
    background-color: #7fbdcd;
    margin-top: 0.1875rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem 1rem;
    border-radius: 1rem;
    cursor: pointer;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    background 150ms cubic-bezier(0, 0, 0.58, 1);
}

.fab::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #66b0c4;
    border-radius: inherit;
    box-shadow: 0 0.625rem 0 0 #a7d1dd;
    transform: translate3d(0, 0.75rem, -1rem);
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}

.fab:hover {
    background: #c4e0e8;
    transform: translate(0, 0.25rem);
}

.fab:hover::before {
    box-shadow: 0 0.5rem 0 0 #a7d1dd;
    transform: translate3d(0, 0.5rem, -1rem);
}

.fab:active {
    background: #c4e0e8;
    transform: translate(0rem, 0.75rem);
}

.fab:active::before {
    box-shadow: 0 0 #a7d1dd;
    transform: translate3d(0, 0, -1rem);
}

.fab p {
    color: #333;
    font-size: 1rem;
    font-weight: 600;
    line-height: 160%;
}

.fab img {
    width: 2.5rem;
}

/* ----------------------------------------------태블릿*/
@media all and (min-width: 753px) and (max-width: 1440px) {

    .section1 {
        height: 86vh;
    }

    .inner.section__inner {
        width: 90%;
        overflow: hidden;
    }

    .subtitle p.desc-text {
        line-height: 130%;
    }

    .subtitle p.desc-text.desc-text-1 {
        font-size: 1.75rem;
    }

    .subtitle p.desc-text.desc-text-2 {
        font-size: 1.125rem;
    }

    /* header */
    .inner.header__inner {
        width: 90%;
    }

    .gnb a.gnb__btn {
        font-size: 0.875rem;
    }

}
/* 태블릿 가로 */
@media all and (min-width: 1024px) and (max-width: 1440px) { }
/* 태블릿 세로 */
@media all and (min-width: 753px) and (max-width: 1023px) {
    .fab {
        bottom: 2.5vh;
    }
}

/* ---------------------------------------------------------모바일 */
@media all and (max-width: 752px) {
    .sec4_noData {
        height: 47vh;
    }

    .inner {
        width: 90%;
    }

    /* 수정 */
    .subtitle .page-name {
        font-size: 1.2rem;
        margin: 1vh auto;
        padding: 4px 16px;
    }
    .subtitle {
        gap: 0.5rem;
    }

    /* 수정 */
    .subtitle p.desc-text {
        line-height: 130%;
    }
    /* 수정 */
    .subtitle p.desc-text.desc-text-1 {
        font-size: 1.3rem;
    }
    /* 수정 */
    .subtitle p.desc-text.desc-text-2 {
        font-size: 1.2rem;
    }


    .gnb {
        display: none;
    }

    .fab {
        transform: scale(.8);
        top: .8%;
        height: 2.4rem;
    }
    .fab img {
        display: none;
    }
    /* -------------------------section1--------------------- */

    .sec1 .main-slide-text-inner {
        width: 86%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 20%;
    }

    /* 수정 */
    .swiper.mainSwiper .slide-text-box .text-box__top {
        font-size: 180%;
    }
    /* 수정 */
    .swiper.mainSwiper .slide-text-box .text-box__bottom {
        width: 100%;
        font-size: 120%;
    }

    /* 수정 */
    .swiper.mainSwiper .swiper-pagination {
        left: 6%;
        bottom: 10%;
    }

    /* 수정 */
    #fp-nav.fp-right {
        right: 2%;
    }
}